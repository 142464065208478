.gallery a {
	text-decoration:none;
}
.gallery figcaption {
	display:none;
}
.gallery img {
	max-width:100%;
}
@media (min-width: 576px) {
	.gallery-container {
		overflow:hidden;
		white-space:nowrap;
		text-align:center;
		width:100vw;
		position:relative;
		left:50%;
		right:50%;
		margin-left:-50vw;
		margin-right:-50vw;
	}
	.gallery {
		display:block;
		overflow-x:scroll;
		overflow-y:hidden;
		-webkit-overflow-scrolling:touch;
		padding:1em 80px;
		margin-bottom:1em;
	}
	.gallery figure {
		display:inline;
	}
	.gallery img {
		border-radius:3px;
		object-fit:cover;
	}
	.gallery img.small {
		height:420px;
		margin:0 .5em;
		max-width:100%;
	}
	.gallery img.medium {
		width:100%;
		max-height:720px;
	}
}
