@import 'base';

header {
//	background-image: url("/images/background.png");
//	background-repeat: repeat;
//	background-size:800px;
//
//	background-color:#222;
}
header .header-text {
	padding:1em;
}
header img {
	float:left;
	margin:2px .5em 1em;
	width:90px;
	height:90px;
}
header h1, header a, header a:hover, header .links {
	color:#d9d9d9;
	font-family:$body-font;
	font-weight:300;
	text-transform: uppercase;
	font-size:1.15em;
}
header .title {
	display:none;
}
header i {
	margin-left:1em;
}
header nav {
	float:right;
	display:inline;
}

@media (min-width: 576px) {
	header .title {
		display:inline;
	}
	header img {
		width:110px;
		height:110px;
		margin:2px .5em 0;
	}
	header i {
		margin-left:.5em;
	}
}
