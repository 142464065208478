
/*Email signup form*/
.email-list-signup {
	width:100%;
	background-color:#f9f9f9;
	text-align:center;
	border-radius:3px;
	margin:1em 0;
	padding:.5em .5em;
}
@media (min-width: 576px) {
	.email-list-signup {
		display:flex;
	}
}
.email-list-signup label {
	margin:.5em 0;
	padding:0 1em;
	color:#686868;
	line-height:1.5;
}
.email-list-signup .input-group {
	margin:auto .5em auto 0;
	justify-content:flex-end;
	height:2.6em;
}
.email-list-signup input {
	padding:.25em .5em;
}
.email-list-signup input[type=email] {
	border:1px solid #d9d9d9;
	width:calc(100% - 100px);
	outline:none;
}
.email-list-signup input[type=submit] {
	border:1px solid #d9d9d9;
	background-color:#d9d9d9;
	color:#686868;
	width:100px;
}
.email-list-signup input[type=submit]:hover {
	color:#222;
}
