/*Share Button*/
.share-menu {
	cursor:pointer;
}
.dropdown {
	position:relative;
	display:inline-block;
}
.dropdown-content {
	display: none;
	position:absolute;
	right:0;
	background-color:#f9f9f9;
	box-shadow:0px 8px 16px 0px rgba(0,0,0,0.1);
	z-index:1;
}
.dropdown-content a {
	color:#686868;
	padding:16px 140px 16px 20px;
	text-decoration:none;
	display:block;
}
.dropdown-content a:hover {
	color:#000;
	background-color:#f1f1f1;
	text-decoration:underline;
}
.dropdown:hover .dropdown-content {
	display:block;
}
