@import 'base';
@import 'header';
@import 'gallery';
@import 'email-signup';
@import 'share-button';

/*Main Page*/
body {
	font-family:$body-font;
	font-weight:300;
	line-height:1.7;
	background-image: url("/images/background.png");
	background-color:#222;
	background-size:600px;
}
h1, h2, h3, h4 {
	font-weight:400;
	font-family:$title-font;
}
#page {
	background-color:#fff;
	color:#222;
}
main {
	max-width:960px;
	margin:0 auto;
}
.button {
	background-color:#222;
	margin:0 auto 2em auto;
	max-width:360px;
	display:block;
	text-align:center;
	font-family:$title-font;
	font-weight:400;
	font-size:1.2em;
	padding:.5em;
	border-radius:3px;
	text-transform: uppercase;
	color:#fff;
	text-decoration:none;
}
.button:hover {
	color:#fff;
}

/*Main Page Feature*/
.desktop {
	display:none;
}
.mobile {
	display:block;
	text-align:center;
}
@media (min-width: 576px) {
	.desktop {
		display:block;
	}
	.mobile {
		display:none;
	}
}

/*Main Page Titlecard Thumbnail*/
.titlecard-a .thumbnail {
	position:relative;
}
.titlecard-a .thumbnail:after {
	content:"";
	display:block;
	padding-bottom:100%;
}
.titlecard-a .thumbnail img {
	position:absolute;
	width:100%;
	height:100%;
	object-fit:cover;
	border-radius:3px;
}

/*Main Page Titlecard Post Title*/
.titlecard-a {
	margin-top:2em;
}
.titlecard-a, .titlecard-c {
	margin-bottom:2em;
}
.titlecard-a h2, .titlecard-a p {
	text-align:center;
}
.titlecard-a h2 {
	font-size:1.5em;
	margin:.5em 0;
}
.titlecard-a p, .titlecard-c p {
	font-style:italic;
	font-size:1.1em;
	line-height:1.0;
	margin:0;
	color:#686868;
}

/*Main Page links*/
.titlecard-a h2 a, .titlecard-b h2 a, .titlecard-c h2 a {
	text-decoration:none;
	color:inherit;
}
.titlecard-a h2 a:hover, .titlecard-b h2 a:hover, .titlecard-c h2 a:hover, a.button:hover {
	text-decoration:underline;
}

/*Section and Article Titles*/
main h1 {
	clear:both;
	font-size:1.8em;
	font-family:$title-font;
	text-align:center;
}
@media (min-width: 576px) {
	main h1 {
		font-size:2.8em;
	}
}

/*Main Page General*/
section h1.title {
	margin:1em;
	color:#ccc;
}
section p.subtitle {
	margin-bottom:1em;
	color:#686868;
	font-size:1.1em;
}
hr.starred {
	line-height:20px;
	margin:2em 0;
}
hr.starred:after {
	content:'\2605  \2605  \2605';
	color:#ccc;
	font-size:1.2em;
	display:block;
	text-align:center;
	background-color:#fff;
	width:100px;
	margin-top:-12px;
	position:absolute;
	left:50%;
	-webkit-transform:translate(-50%);
	-moz-transform:translate(-50%);
	-ms-transform:translate(-50%);
	-o-transform:translate(-50%);
	transform:translate(-50%);  
}
/*Main Page Small Titlecard List*/
.titlecard-b, .titlecard-c {
	height:100%;
	padding-bottom:1em;
	min-height:220px;
}
.titlecard-b img, .titlecard-c img {
	object-fit:cover;
	border-radius:3px;
	width:100%;
	max-height:220px;
}
.titlecard-b h2, .titlecard-c h2 {
	font-size:1.5em;
	margin:.5em 0;
	color:inherit;
	text-align:center;
}
.titlecard-b .location {
	text-transform:uppercase;
	color:#000;
}
.titlecard-b p {
	color:#686868;
 }
 
/* Article Page */
article p {
	font-size:1.1em;
 }
article h1 {
	color:#222;
	margin:1em 0 0;
}
article h2 {
	text-align:center;
	font-size:1.5em;
	color:#ccc;
	margin:.5em;
}
article a {
	color:inherit;
	text-decoration:underline;
}
article blockquote {
	font-size:125%;
	font-style:italic;
	color:#7f7f7f;
	margin:2em 4em;
}
article p.attribution {
	color:#ccc;
	font-size:1em;
	text-align:center;
}
article hr {
	margin:2em 0;
}

/* Footer */
footer {
	padding:.5em;
	text-align:center;
}
footer p {
	margin:.25em 0 1em;
}
footer a {
	color:#222;
	margin:0 .3em;
}
